import React from 'react'

// Components
import HeroImgSegment from "./hero-segment/heroSegment"
import OfferingSegment from "./offering-segment/offeringSegment"

export default function Home() {
	return (
		<div>
			<HeroImgSegment/>
			<OfferingSegment/>
		</div>
	)
}
