import React from "react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles, styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Window size
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { PageWidth } from "../../helpers/pageWidth";

// Images
import pvGreenCardLogo from "../../assets/site/pvgreencard.png";

// Components
import HeroAboutSegment from "./hero-segment/hero-about-segment"

// Styling
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: "600px 10px 50px 10px",
		maxWidth: "100%"
	},
	Typography: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
	},
	title: {
		fontColor: "#000",
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		margin: "0px 0px 20px 0px",
	},
	subTitle: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
		fontSize: "1.2rem",
		margin: "0 0 15px 0"
	},
	paper: {
		padding: theme.spacing(6),
		textAlign: "center",
		margin: "30px",
		minHeight: "230px"
	},
	"@media (max-width: 765px)": {
		paper: {
			elevation: 0,
			boxShadow: "none",
			// padding: theme.spacing(0),
			padding: "0px",
			margin: "20px 0px",
			// minHeight: "400px"
		},

		solutionBox: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},

		solutionImg: {
			order: -1
		},
	},
	img: {
		maxHeight: "80px",
	},
	".subtext": {
		marginTop: "20px",
	},
	arrowMargin: {
		textAlign: "center",
		marginTop: 20,
		marginBottom: 60,
	},

	arrowBox: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		position: "relative",
		boxSizing: "border-box",

		'&:before': {
			content: '""',
			border: "solid 4px #fcca03",
			borderWidth: "4px 0 0 4px",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			top: -14,
			left: -60,
		},

		'&:after': {
			content: '""',
			border: "solid 2px #fcca03",
			borderWidth: "0 4px 4px 0",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			right: -60,
			bottom: -32,

		}
	},
	section: {
		margin: "0 0 40px 0",
	},
}));


export default function About() {
	const classes = useStyles();

	return (
		<div>
			<HeroAboutSegment />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.root}
			>

				<Grid item xs={10} md={5} lg={5}>
					<Typography variant="h3" className={classes.arrowMargin} >
						<span className={classes.arrowBox} >Our Story</span>
					</Typography>

					<Typography className={classes.subTitle} variant="body1">
						An EPC Founded in 2017 and currently operating in the South African residential and commercial market
					</Typography>

					<Typography className={classes.subTitle} variant="body1">
						Offering end-to-end consulting services as well as delivery of off-grid, grid-tied or hybrid renewable energy solutions
					</Typography>

					<Typography className={classes.subTitle} variant="body1">
						Able to deliver immediate and measurable value through our sophisticated renewable energy solutions
					</Typography>
				</Grid>

				{/* Values */}
				<Grid
					container
					item
					className={classes.section}
					justify="space-around"
				>
					{/* Item 1 */}
					<Grid item xs={10} md={6} lg={6} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3}>
							{/* <img src={flatIcon1} alt="Solar Panel image" className={classes.flatIcon} /> */}
							<Typography className={classes.title} variant="h6">
								Who we are: Family owned with family values
					</Typography>

							<Typography className={classes.subTitle} variant="body1">
								Formally established in 2017 after the founding members both left engineering industry to form their own practice.
								This came about as a response to the dire need for innovative energy solutions in South Africa
					</Typography>
						</Paper>
					</Grid>


					{/* Item 2 */}
					<Grid item xs={10} md={6} lg={6}>
						<Paper className={classes.paper} elevation={3}>
							{/* <img src={flatIcon2} alt="Energy Saving image" className={classes.flatIcon} /> */}
							<Typography className={classes.title} variant="h6">
								What we can do: Engineering and design thinkers
					</Typography>

							<Typography className={classes.subTitle} variant="body1">
								Solar365 is formed through deep expertise of their leaders with over 65+ years of combined experience in electrical,
								civil and structural engineering whilst being equipped with masters level qualifications in engineering and management
								and certifications such as Pr.Eng and PMP. Experience in delivering large scale engineering projects and managing customer
								sites across SA
					</Typography>
						</Paper>
					</Grid>
				</Grid>



				<img src={pvGreenCardLogo} alt="PVGreenCard Certificate" className={classes.img} />
			</Grid>
		</div>
	)
}
