import React from "react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles, styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Window size
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { PageWidth } from "../../helpers/pageWidth";

// Images
import SolarVector from "../../assets/site/solar-vector.jpg";

// Components
import QuoteForm from './quoteForm'

// Styling
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		alignItems: "center",
		justify: "center",
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		// color: theme.palette.text.secondary,
		// margin: "100px 20px 20px 30px",
	},
	"@media (max-width: 960px)": {
		paper: {
			elevation: 0,
			boxShadow: "none",
			// padding: theme.spacing(0),
			padding: "0px",
			margin: "0px",
			// padding: "15px"
		},
	},
	title: {
		textAlign: "center",
		fontColor: "#000",
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		margin: "0px 0px 20px 0px",
	},
	subTitle: {
		textAlign: "center",
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
		fontSize: "1.2rem",
		margin: "0 0 15px 0",
		maxWidth: "500px",
	},
	img: {
		maxHeight: "400px",
		width: "90vw",
		maxWidth: "400px",
	},
	arrowMargin: {
		textAlign: "center",
		marginTop: 20,
		marginBottom: 60,
	},

	arrowBox: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		position: "relative",
		boxSizing: "border-box",

		'&:before': {
			content: '""',
			border: "solid 4px #fcca03",
			borderWidth: "4px 0 0 4px",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			top: -14,
			left: -60,
		},

		'&:after': {
			content: '""',
			border: "solid 2px #fcca03",
			borderWidth: "0 4px 4px 0",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			right: -60,
			bottom: -32,

		}
	},
	section: {
		margin: "0 0 40px 0",
		padding: "0 10px",
	},
	sendMessageButton: {
		background: "linear-gradient(45deg, #82c548 30%, #baeb16 90%)",
		width: "120px",
		// boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
		border: 0,
		borderRadius: 3,
		color: "white",
		margin: "0 20px",
		height: 48,
		padding: "0 15px",
		"&:hover": {
			background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
			// boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
		},
	},
}));

export default function Quote() {
	const classes = useStyles();
	var { width } = useWindowDimensions();

	if (width > PageWidth.md) {
		width = width / 2;
	}

	return (
		<div className={classes.root}>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.root}
			>
				<Grid container className={classes.section} justify="center" direction="column" alignItems="center" justify="center">
					<Typography className={classes.title, classes.arrowMargin} variant="h4">
						<span className={classes.arrowBox} >Quoting Tool</span>
					</Typography>
					<img src={SolarVector} alt="website logo" className={classes.img} />
					<Typography className={classes.subTitle} variant="body1">
						By filling out this form, you will receive your quotation* for your solar system and/or battery back-up solution instantly. The estimate will list the price of a fully installed system.
					</Typography>
				</Grid>
				
				<QuoteForm />

			</Grid>
		</div>
	);
}
