import img1 from "../../assets/gallery/01_thumbnail.jpg";
import img2 from "../../assets/gallery/02_thumbnail.jpg";
import img3 from "../../assets/gallery/03_thumbnail.jpg";
import img4 from "../../assets/gallery/04_thumbnail.jpg";
import img5 from "../../assets/gallery/05_thumbnail.jpg";
import img6 from "../../assets/gallery/06_thumbnail.jpg";
import img7 from "../../assets/gallery/07_thumbnail.jpg";
import img8 from "../../assets/gallery/08_thumbnail.jpg";
import img9 from "../../assets/gallery/09_thumbnail.jpg";

var _cols = 1;

export const tileData = [
  {
    img: img1,
    title: "1",
    author: "",
    cols: _cols,
  },
  {
    img: img2,
    title: "2",
    author: "",
    cols: _cols,
  },
  {
    img: img3,
    title: "3",
    author: "",
    cols: _cols,
  },
  {
    img: img4,
    title: "4",
    author: "",
    cols: _cols,
  },
  {
    img: img5,
    title: "5",
    author: "",
    cols: _cols,
  },
  {
    img: img6,
    title: "6",
    author: "",
    cols: _cols,
  },
  {
    img: img7,
    title: "7",
    author: "",
    cols: _cols,
  },
  {
    img: img8,
    title: "8",
    author: "",
    cols: _cols,
  },
  {
    img: img9,
    title: "9",
    author: "",
    cols: _cols,
  },
];

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *     cols: 2,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
