import React from "react";

// Material-ui
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Images
import heroImgSrc2 from "../../../assets/site/about-01.jpg";

// Styling
const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		height: "600px",
		// background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${heroImgSrc2})`,
		background: `linear-gradient( rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.3) ), url(${heroImgSrc2})`,
		backgroundRepeat: "no-repeat",
		// backgroundAttachment: "fixed",
		backgroundSize: "cover",
		// backgroundColor: "rgba(0,0,0,0.7)",

		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		zIndex: -1,
	},
	title: {
		color: "#FFF",
		fontFamily: "raleway, sans-serif",
		fontWeight: "900",
		fontSize: "5rem",
	},
	subTitle: {
		color: "#FFF",
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
		fontSize: "1.5rem",
	},

	"@media (max-width: 765px)": {
		title: {
			fontSize: "4rem",
			margin: "20px"
		},
		subTitle: {
			margin: "20px"
		},
	},
	img: {
		maxHeight: "80px",
	},
}));

export default function HeroAboutSegment() {
	const classes = useStyles();

	return (
		<Grid
			container
			className={classes.root}
			direction="column"
			justify="center"
			alignItems="center"
		>
			<Typography className={classes.title} variant="h3">
				About us
			</Typography>
			<Typography className={classes.subTitle} variant="body1" align="center">
				Our team has over 65 years of combined engineering and project{" "}<br />
				management experience with professional certifications including{" "}<br />
				PMP and Pr.Eng and experience across, India, Africa and Europe.
			</Typography>

			{/* <img src={pvGreenCardLogo} alt="PVGreenCard Certificate" className={classes.img} />	 */}
		</Grid>
	);
}
