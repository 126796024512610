import React from "react";

// Material UI
import { makeStyles, styled } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

// Pages
import Home from "../../pages/home/home";
import Quote from "../../pages/quote/quote";
import Gallery from "../../pages/gallery/gallery";
import About from "../../pages/about/about";
import ContactUs from "../../pages/contact/contact-us.js"

// Burger Menu Component
import TemporaryDrawer from "./burger-menu/burgerMenu";

// React Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

// Images
import Logo from "../../assets/logo/solar365_logo_hori.png";

// Styles
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appbar: {
		color: "black",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		margin: "0 0 0 5vw",
	},
	navbar: {
		boxShadow: "none",
		backgroundColor: "rgba(255, 255, 255, 0.0)",
	},
	getQuoteButton: {
		background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
		// boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
		border: 0,
		borderRadius: 3,
		color: "white",
		margin: "0 20px",
		height: 48,
		padding: "0 15px",
		"&:hover": {
			background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
			boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
		},
	},
	pageButton: {
		margin: "0 20px",
		color: "#333"
	},
	logo: {
		maxHeight: "50px",
		marginTop: "1rem",
	},
	"@media (max-width: 765px)": {
		horizontalNavbar: {
			display: "none",
		},
	},
}));

export default function Navbar() {
	const classes = useStyles();

	return (
		<Router>
			<div className={classes.root, classes.navbar}>
				<AppBar position="static" className={classes.appbar, classes.navbar}>
					<Toolbar className={classes.navbar}>
						{/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}

						<Typography
							variant="h6"
							className={classes.title}
							component={RouterLink}
							to="/"
						>
							<img src={Logo} alt="website logo" className={classes.logo} />
						</Typography>

						<div className={classes.horizontalNavbar}>
							<Button
								color="inherit"
								component={RouterLink}
								to="/gallery"
								className={classes.pageButton}
							>
								GALLERY
							</Button>

							<Button
								color="inherit"
								component={RouterLink}
								to="/about"
								className={classes.pageButton}
							>
								ABOUT US
							</Button>

							<Button
								color="inherit"
								component={RouterLink}
								to="/contact"
								className={classes.pageButton}
							>
								CONTACT US
							</Button>

							<Button
								color="secondary"
								variant="outlined"
								component={RouterLink}
								to="/quote"
								className={classes.getQuoteButton}
							>
								GET A QUOTE
							</Button>
						</div>

						<TemporaryDrawer/>

					</Toolbar>
				</AppBar>

				{/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
				<Switch>
					<Route path="/gallery">
						<Gallery />
					</Route>
					<Route path="/about">
						<About />
					</Route>
					<Route path="/quote">
						<Quote />
					</Route>
					<Route path="/contact">
						<ContactUs />
					</Route>
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}
