import React, { useState } from "react";

// Material UI
import Grid from "@material-ui/core/Grid";
import { makeStyles, styled } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Network - form submit
import { Axios, db } from '../../firebase/firebaseConfig'

// Components
import ContactForm from './contactform'

// Styling
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: "0 0 50px 0",
		maxWidth: "100%"
	},
	Typography: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
	},
	title: {
		fontColor: "#000",
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		margin: "0px 0px 20px 0px",
	},
	subTitle: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
		fontSize: "1.2rem",
		margin: "0 0 15px 0"
	},
	paper: {
		padding: theme.spacing(6),
		textAlign: "center",
		margin: "30px",
		minHeight: "230px"
	},
	"@media (max-width: 765px)": {
		paper: {
			elevation: 0,
			boxShadow: "none",
			padding: "0px",
			margin: "20px 0px"
		},

		solutionBox: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},

		solutionImg: {
			order: -1
		},
	},
	img: {
		maxHeight: "80px",
	},
	".subtext": {
		marginTop: "20px",
	},
	arrowMargin: {
		textAlign: "center",
		marginTop: 20,
		marginBottom: 60,
	},

	arrowBox: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		position: "relative",
		boxSizing: "border-box",

		'&:before': {
			content: '""',
			border: "solid 4px #fcca03",
			borderWidth: "4px 0 0 4px",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			top: -14,
			left: -60,
		},

		'&:after': {
			content: '""',
			border: "solid 2px #fcca03",
			borderWidth: "0 4px 4px 0",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			right: -60,
			bottom: -32,

		}
	},
	section: {
		margin: "0 0 40px 0",
	},
	sendMessageButton: {
		background: "linear-gradient(45deg, #82c548 30%, #baeb16 90%)",
		width: "120px",
		// boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
		border: 0,
		borderRadius: 3,
		color: "white",
		margin: "0 20px",
		height: 48,
		padding: "0 15px",
		"&:hover": {
			background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
			// boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
		},
	},
	textInput: {
		margin: "15px 0px",
		maxWidth: "400px",
		width: "90vw"
	},
}));

// Options
const options = [
	{
		value: 'Backup',
		label: 'Backup',
	},
	{
		value: 'Hybrid',
		label: 'Hybrid',
	},
	{
		value: 'Off-grid',
		label: 'Off-grid',
	},
	{
		value: 'Solar Geysers',
		label: 'Solar Geysers',
	},
	{
		value: 'Other',
		label: 'Other',
	},
];



// export default function ContactUs() {
const ContactUs = () => {
	const classes = useStyles();
	const [option, setOption] = React.useState('Backup');
	const [formData, setFormData] = React.useState({});

	return (
		<div>
			{/* <HeroAboutSegment /> */}
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.root}
			>
				<Grid item className={classes.section} xs={10} md={4} lg={4}>
					<Typography className={classes.title, classes.arrowMargin} variant="h4">
						<span className={classes.arrowBox} >Contact Us</span>
					</Typography>
					<Typography className={classes.subTitle} variant="body1">
						Ready to start your journey of going green <br />and cutting costs?
					</Typography>
				</Grid>
				
				<ContactForm/>

			</Grid>
		</div>
	)
}

export default ContactUs