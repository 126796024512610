import React from "react";
import { useState, useEffect } from 'react';

// Material-ui
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";

// React Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

// Images
import flatIcon1 from "../../../assets/site/008-solar-panel.svg";
import flatIcon2 from "../../../assets/site/031-energy-saving.svg";
import flatIcon3 from "../../../assets/site/017-research.svg";
import flatIcon4 from "../../../assets/site/023-lamp.svg";

import batteryImg from "../../../assets/site/battery.png";
import hybridImg from "../../../assets/site/hybrid.png";
import offgridImg from "../../../assets/site/offgrid.png";
import solarGeyserImg from "../../../assets/site/solar-geyser.png";
import solarCommercialImg from "../../../assets/site/solar-commercial.png";
import evChargingImg from '../../../assets/site/ev-charging.jpg';
import pvGreenCardLogo from "../../../assets/site/pvgreencard.png";

// Styling
const useStyles = makeStyles((theme) => ({
	Typography: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
	},

	Box: {
		height: "100%",
	},

	root: {
		flex: 1,
		margin: "800px 10px 50px 10px",
		maxWidth: "100%"
	},
	title: {
		fontColor: "#000",
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		margin: "0px 0px 20px 0px",
	},
	subTitle: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
		fontSize: "1.2rem",
	},
	section: {
		margin: "0 0 40px 0",
	},

	arrowBox: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		position: "relative",
		boxSizing: "border-box",

		'&:before': {
			content: '""',
			border: "solid 4px #fcca03",
			borderWidth: "4px 0 0 4px",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			top: -14,
			left: -60,
		},

		'&:after': {
			content: '""',
			border: "solid 2px #fcca03",
			borderWidth: "0 4px 4px 0",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			right: -60,
			bottom: -32,

		}
	},

	arrowMargin: {
		marginBottom: 60,
		textAlign: "center",
	},

	paper: {
		padding: theme.spacing(4),
		textAlign: "center",
		margin: "30px"
	},

	flatIcon: {
		maxWidth: "20%",
		maxHeight: "20%",
		margin: 20,
	},

	solutionImg: {
		objectFit: "scale-down",
		width: "50%",
		height: "200px",
		margin: 10,
	},

	solutions: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%"
	},

	getQuoteButton: {
		background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
		// boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
		border: 0,
		borderRadius: 3,
		color: "white",
		margin: "0 20px",
		height: 48,
		padding: "0 15px",
		"&:hover": {
			background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
			boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
		},
	},

	pvGreenCardImg: {
		maxHeight: "80px",
		margin: "40px 0 0 0",
	},

	"@media (max-width: 765px)": {
		paper: {
			elevation: 0,
			boxShadow: "none",
			// padding: theme.spacing(0),
			padding: "0px",
			margin: "20px 0px"
		},

		solutionBox: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			minHeight: "100%"
		},

		solutionImg: {
			order: -1
		},
	},

}));

// function

export default function OfferingSegment() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
			>
				<Grid item className={classes.section} xs={10} md={4} lg={4}>
					<Typography className={classes.title, classes.arrowMargin} variant="h4">
						<span className={classes.arrowBox} >What We Can Offer You</span>
					</Typography>
					<Typography className={classes.subTitle} variant="body1">
						Solar365 is a leading solar power company based in Johannesburg
						dedicated to the design and delivery of affordable & efficient custom
						rooftop solar power solutions. We have a key presence in both the
						commercial and residential markets. At Solar365, we are dedicated to
						delivering value to our clients through the following benefits:
					</Typography>
				</Grid>

				{/* Offerings */}
				<Grid
					container
					item
					className={classes.section}
					justify="space-around"
					alignItems="stretch"
				>
					{/* Item 1 */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3}>
							<img src={flatIcon1} alt="Solar Panel image" className={classes.flatIcon} />
							<Typography className={classes.title} variant="h6">
								Professional and Certified PV Solution
							</Typography>

							<Typography className={classes.subTitle} variant="body1">
								We are a PVGreenCard certified installer with a proven track record of 
								successful delivery. We cater for
								grid-tied and backup solutions ranging from 5kW to 100kW. Your PV
								solution will be engineered with the highest standards. We take
								pride in our work and understand that our workmanship and value
								speaks on our behalf. Therefore all work is certified with an
								electrical CoC to ensure that it meets and exceeds the standards
								expected of us.
							</Typography>
						</Paper>
					</Grid>


					{/* Item 2 */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3}>
							<img src={flatIcon2} alt="Energy Saving image" className={classes.flatIcon} />
							<Typography className={classes.title} variant="h6">
								Energy Auditing
							</Typography>

							<Typography className={classes.subTitle} variant="body1">
								We design your solution based on facts and tangible evidence. We
								review your existing demand and energy needs and tailor make a
								solution that allows you to live your life unencumbered. Through an
								energy audit we will seek to discover any opportunities for you to
								save further energy and money.
							</Typography>
						</Paper>
					</Grid>

					{/* Item 3 */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3}>
							<img src={flatIcon3} alt="Research image" className={classes.flatIcon} />
							<Typography className={classes.title} variant="h6">
								Premium Consulting
							</Typography>

							<Typography className={classes.subTitle} variant="body1">
								With over 65 years of engineering and project management experience,
								we are seasoned experts that are well positioned to advise you on
								the right solution suited to your needs and budget.
							</Typography>
						</Paper>
					</Grid>

					{/* Item 4 */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3}>
							<img src={flatIcon4} alt="Lamp image" className={classes.flatIcon} />
							<Typography className={classes.title} variant="h6">
								Professional After-Sales Support
							</Typography>

							<Typography className={classes.subTitle} variant="body1">
								We have partnered with the leading suppliers and manufacturers to
								bring you the highest quality products in the local market. We
								therefore remain with you on this journey to support you should your
								need our assistance once their products are installed. We service
								and provide warranties on our components as well as our workmanship.
							</Typography>
						</Paper>
					</Grid>

				</Grid>

				{/* Solutions */}
				<Typography className={classes.title, classes.arrowMargin} variant="h4">
					<span className={classes.arrowBox} >Solutions</span>
				</Typography>

				<Grid
					container
					item
					className={classes.section}
					justify="space-around"
				>
					{/* Battery Solution */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3} >
							<Box display="flex" flexDirection="row" className={classes.solutionBox}>
								<img src={batteryImg} alt="Battery image" className={classes.solutionImg} />

								<Box display="flex" flexDirection="column" className={classes.solutions}>
									<Typography className={classes.title} variant="h6">
										Backup
									</Typography>

									<Typography className={classes.subTitle} variant="body1">
										When the power goes out, your world doesn't stop. You will have an emergency 
										power supply to do the things that matter most.
									</Typography>
								</Box>
							</Box>
						</Paper>
					</Grid>

					{/* Solar Geyser Solution */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3} >
							<Box display="flex" flexDirection="row" className={classes.solutionBox}>
								<Box display="flex" flexDirection="column" className={classes.solutions}>
									<Typography className={classes.title} variant="h6">
										Commercial Solar
									</Typography>

									<Typography className={classes.subTitle} variant="body1">
										Solar365 creates long-lasting commercial and industrial scale solar solutions. We provide complete turnkey solutions for your peace of mind. Contact our team today for a comprehensive quote.
									</Typography>
								</Box>

								<img src={solarCommercialImg} alt="Solar Geyser image" className={classes.solutionImg} />
							</Box>
						</Paper>
					</Grid>

					{/* Hybrid Solution */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch" justifyContent="center">
						<Paper className={classes.paper} elevation={3} >
							<Box container flexDirection="row" className={[classes.solutions, classes.solutionBox]} justifyContent="center" alignItems="center">
								<img src={hybridImg} alt="Battery image" className={classes.solutionImg} />

								<Box container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
									<Typography className={classes.title} variant="h6">
										Hybrid
									</Typography>

									<Typography className={classes.subTitle} variant="body1">
										Get the best of both worlds. Backup power during load shedding and the benefit of significant 
										energy savings.
									</Typography>
								</Box>
							</Box>
						</Paper>
					</Grid>


					{/* Off Grid Solution */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3} >
							<Box display="flex" flexDirection="row" className={classes.solutionBox}>
								<Box display="flex" flexDirection="column" className={classes.solutions}>
									<Typography className={classes.title} variant="h6">
										Off Grid
									</Typography>

									<Typography className={classes.subTitle} variant="body1">
										Never be dependent on municipal power again. When the world goes dark, you remain 
										unaffected. Load shedding worries are a thing of the past. A robust solution for 
										those who want to take the power back into their own hands.
									</Typography>
								</Box>

								<img src={offgridImg} alt="Battery image" className={classes.solutionImg} />
							</Box>
						</Paper>
					</Grid>

					{/* EV Charging */}
					<Grid item xs={10} md={5} lg={5} container alignItems="stretch">
						<Paper className={classes.paper} elevation={3} >
							<Box display="flex" flexDirection="row" className={classes.solutionBox}>
								<Box display="flex" flexDirection="column" className={classes.solutions}>
									<Typography className={classes.title} variant="h6">
										EV Charging
									</Typography>

									<Typography className={classes.subTitle} variant="body1">
									Solar365 supports the EV future for South Africa and offers residential and commercial scale EV chargers.
									</Typography>
								</Box>

								<img src={evChargingImg} alt="Battery image" className={classes.solutionImg} />
							</Box>
						</Paper>
					</Grid>
				</Grid>


				{/* Off Grid Solution */}
				<Grid item xs={10} md={5} lg={5} >
					<Box display="flex" flexDirection="column">
						<Button
							color="secondary"
							variant="outlined"
							component={RouterLink}
							to="/quote"
							className={classes.getQuoteButton}
						>
							GET A QUOTE
						</Button>

						<img src={pvGreenCardLogo} alt="PVGreenCard Certificate" className={classes.pvGreenCardImg} />
					</Box>
				</Grid>


			</Grid>
		</div>
	);
}
