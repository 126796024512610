import React from 'react';

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from "@material-ui/core/Button";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

// React Router
import { Link as RouterLink } from "react-router-dom";

// Styles
const useStyles = makeStyles({
	list: {
		width: 250,
	},
	fullList: {
		width: 'auto',
	},
	burgerMenu: {
		color: "#000",
	},

	"@media (min-width: 766px)": {
		burgerMenu: {
			display: "none",
		},
	},

	getQuoteButton: {
		background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
		// boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
		border: 0,
		borderRadius: 3,
		color: "white",
		margin: "0 20px",
		height: 48,
		padding: "0 15px",
		"&:hover": {
			background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
			boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
		},
	},

	drawerList: {
		// margin: "20px 0px",
		alignItems: "center",
	}
});

export default function TemporaryDrawer() {
	const classes = useStyles();
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === 'top' || anchor === 'bottom',
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List className={classes.drawerList}>
				<ListItem>
				<Button
					color="inherit"
					component={RouterLink}
					to="/gallery"
					className={classes.pageButton}
				>
					GALLERY
				</Button>
				</ListItem>

				<ListItem>
				<Button
					color="inherit"
					component={RouterLink}
					to="/about"
					className={classes.pageButton}
				>
					ABOUT US
				</Button>
				</ListItem>

				<ListItem>
				<Button
					color="inherit"
					component={RouterLink}
					to="/contact"
					className={classes.pageButton}
				>
					CONTACT US
				</Button>
				</ListItem>

				<ListItem>
				<Button
					color="secondary"
					variant="outlined"
					component={RouterLink}
					to="/quote"
					className={classes.getQuoteButton}
				>
					GET A QUOTE
				</Button>
				</ListItem>
			</List>
		</div>
	);

	return (
		<div className={classes.burger}>
			<React.Fragment key={"top"}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={toggleDrawer("top", true)}
					edge="start"
					className={classes.burgerMenu}
				>
					<MenuIcon />
				</IconButton>

				<Drawer anchor={"top"} open={state["top"]} onClose={toggleDrawer("top", false)}>
						{list("top")}
				</Drawer>
			</React.Fragment>
		</div>
	);
}