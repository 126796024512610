import React, { useState } from 'react'

// Material UI
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Slider from '@material-ui/core/Slider';
import Typography from "@material-ui/core/Typography";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Axios, db } from '../../firebase/firebaseConfig'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "0 0 50px 0",
    maxWidth: "100vw",
  },
  loader: {
    marginTop: "5rem",
    marginBottom: "5rem",
  },
  Typography: {
    fontFamily: "raleway, sans-serif",
    fontWeight: "500",
  },
  title: {
    fontColor: "#000",
    fontFamily: "raleway, sans-serif",
    fontWeight: "700",
    margin: "0px 0px 20px 0px",
  },
  subTitle: {
    fontFamily: "raleway, sans-serif",
    fontWeight: "500",
    fontSize: "1.2rem",
    margin: "0 0 15px 0",
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: "center",
    margin: "30px",
    minHeight: "230px",
  },
  img: {
    maxHeight: "80px",
    alignItems: "center",
    justify: "center",
  },
  ".subtext": {
    marginTop: "20px",
  },
  arrowMargin: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 60,
  },

  arrowBox: {
    fontFamily: "raleway, sans-serif",
    fontWeight: "700",
    position: "relative",
    boxSizing: "border-box",

    "&:before": {
      content: '""',
      border: "solid 4px #fcca03",
      borderWidth: "4px 0 0 4px",
      height: "32px",
      width: "30px",
      display: "block",
      position: "absolute",
      "box-sizing": "border-box",
      top: -14,
      left: -60,
    },

    "&:after": {
      content: '""',
      border: "solid 2px #fcca03",
      borderWidth: "0 4px 4px 0",
      height: "32px",
      width: "30px",
      display: "block",
      position: "absolute",
      "box-sizing": "border-box",
      right: -60,
      bottom: -32,
    },
  },
  section: {
    margin: "0 0 40px 0",
  },
  sendMessageButton: {
    background: "linear-gradient(45deg, #82c548 30%, #baeb16 90%)",
    width: "150px",
    border: 0,
    borderRadius: 3,
    color: "white",
    margin: "0 20px",
    height: 48,
    padding: "0 15px",
    "&:hover": {
      background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    },
  },
  disabledButton: {
    background: "linear-gradient(45deg, #CCC 30%, #CCC 90%)",
  },
  textInput: {
    margin: "15px 0px",
    maxWidth: "400px",
    width: "90vw",
  },
  radioInput: {
    margin: "25px 0px",
  },
  radioLabel: {
    margin: "0 0 10px 0",
  },
  usage: {
    fontWeight: "900",
    fontSize: "2rem",
    margin: "10px 25px",
  },
}));

const PrettoSlider = withStyles({
	root: {
		color: '#82c548',
		height: 8,
		margin: "0 0 25px 0",
		maxWidth: "90vw",
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		marginTop: -8,
		marginLeft: -12,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	mark: {
		backgroundColor: '#FFF',
		height: 8,
		width: 1,
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
})(Slider);


// Show popup message
function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Like a useless function?
function valuetext(value) {
	return "R " + value;
}

const severity = {
	success: "success",
	warning: "warning",
	error: "error"
}

const QuoteForm = () => {
	const classes = useStyles();
	const [formData, setFormData] = useState({});
	const [phaseType, setPhaseType] = React.useState('Single Phase');
	const [electricityUsage, setElectricityUsage] = useState(2250);
	const [visibleSnackBar, setVisibleSnackbar] = React.useState(false);
	const [snackBar, setSnackBar] = useState({ visible: false, severity: severity.success, message: "<Message>" })

  // Overall valid details
  const [validDetails, setValidDetails] = useState(false);
  const [formState, setFormState] = useState(1); // 1 = quote, 2 = loader, 3 = feedback

  // Because react is such a POS
  const [firstNameInteracted, setFirstNameInteracted] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");

  const [lastNameInteracted, setLastNameInteracted] = useState(false);
  const [lastNameError, setLastNameError] = useState("");

  const [mobileInteracted, setMobileInteracted] = useState(false);
  const [mobileError, setMobileError] = useState("");

  const [emailInteracted, setEmailInteracted] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [addressInteracted, setAddressInteracted] = useState(false);
  const [addressError, setAddressError] = useState("");

	// Show snackbar
	const showSnackBar = (severity, message) => {
		setSnackBar({
			visible: true,
			severity: severity,
			message: message
		})

	}

	// Hide snackbar
	const hideSnackBar = () => {
		setSnackBar({
			visible: false
		})
	}

	const openSnackBar = () => {
		setVisibleSnackbar(true);
	};

	const closeSnackBar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setVisibleSnackbar(false);
	};

	// Single phase or 3 phase
	const changePhaseType = (event) => {
		setPhaseType(event.target.value);
	};

	// Changes the electricty usage text
	const changeElectricityUsage = (_, value) => {
		setElectricityUsage(value);
	}

	// Update form data to be submitted
	const updateInput = e => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		})
	}

	// Submit form data
	const handleSubmit = event => {
		event.preventDefault()
		sendEmail()
	}

	const clearFormData = () => {
		setFormData({
			firstName: '',
			lastName: '',
			mobile: '',
			email: '',
			address: '',
			powerSuppleType: phaseType,
			averageElectricitySpend: 'R1000',
		})
	}

  const isValidEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  const isValidMsisdn = (mobile) => {
    const _mobile = mobile.replace(/ /g,'').replace(/-/g, '');  // Remove all whitespace
    let re = /^(\+27|27|0)[0-9]{9}$/;

    if (re.test(_mobile)) {
      return true
    } else {
      return false
    }
  }

	const validateDetails = () => {
    var validDetails = true;

		if (!formData.firstName) {
      setFirstNameError("Please enter your first name");
      validDetails = false;
		} else {
      setFirstNameError("")
    }

    if (!formData.lastName) {
      setLastNameError("Please enter your last name");
      validDetails = false;
    } else {
      setLastNameError("");
    }

    if (!formData.mobile) {
      setMobileError("Please enter your mobile number")
      validDetails = false;
    } else if (!isValidMsisdn(formData.mobile)) {
      setMobileError("Please enter a valid mobile number")
      validDetails = false;
    } else {
      setMobileError("");
    }

    if (!formData.email) {
      setEmailError("Please enter your email address")
      validDetails = false;
    } else if (!isValidEmail(formData.email)) {
      setEmailError("Please enter a valid email address")
      validDetails = false;
    } else {
      setEmailError("");
    }

    // TODO: WTF react
    // if (!formData.address) {
    //   setAddressError("Please enter your address")
    //   validDetails = false;
    // } else {
    //   setAddressError("");
    // }

    setValidDetails(validDetails);
	} 

	// Store quote in firebase
	const sendEmail = () => {
    setFormState(2)

		showSnackBar(severity.error, "Your quote is on its way!")

		var data = {
			email: formData.email,
			first_name: formData.firstName,
			last_name: formData.lastName,
			phone: formData.mobile,
			type_of_power_supply: phaseType,
			average_electricity_spend_rands_incl_vat: "R" + electricityUsage,
			address: formData.address
		}

    const url = process.env.REACT_APP_BASE_URL + "/quote"
    
		Axios.post(
			url,
			data
		)
			.then(res => {
				// clearFormData()
				showSnackBar(severity.success, "Your quote is on its way!")
        setFormState(3)
			})
			.catch(error => {
				console.log(error)
			})
	}

	const form = (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          justify="center"
          className={classes.root}
        >
          {/* First Name */}
          <TextField
            error={firstNameInteracted && !formData.firstName  ? true : false}
						onBlur={(e) => {
              validateDetails();
              setFirstNameInteracted(true);
						}}
            onInput={(e) => {
              validateDetails();
              setFirstNameInteracted(true);
            }}
            helperText={firstNameInteracted ? firstNameError : ""}
            id="outlined-basic"
            type="text"
            name="firstName"
            label="First Name"
            placeholder=""
            variant="outlined"
            className={classes.textInput}
            onChange={updateInput}
            value={formData.firstName}
          />

          {/* Last Name */}
          <TextField
            error={lastNameInteracted && !formData.lastName  ? true : false}
						onBlur={(e) => {
              validateDetails();
              setLastNameInteracted(true);
						}}
            onInput={(e) => {
              validateDetails();
              setLastNameInteracted(true);
            }}
            helperText={lastNameInteracted ? lastNameError : ""}
            id="outlined-basic"
            type="text"
            name="lastName"
            label="Last Name"
            placeholder=""
            variant="outlined"
            className={classes.textInput}
            onChange={updateInput}
            value={formData.lastName}
          />

          {/* Mobile number */}
          <TextField
            error={mobileInteracted && !formData.mobile  ? true : false}
						onBlur={(e) => {
              validateDetails();
              setMobileInteracted(true);
						}}
            onInput={(e) => {
              validateDetails();
              setMobileInteracted(true);
            }}
            helperText={mobileInteracted ? mobileError : ""}
            id="outlined-basic"
            type="tel"
            name="mobile"
            label="Mobile Number"
            placeholder=""
            variant="outlined"
            className={classes.textInput}
            onChange={updateInput}
            value={formData.mobile}
          />

          {/* Email */}
          <TextField
            error={emailInteracted && !formData.email  ? true : false}
						onBlur={(e) => {
              validateDetails();
              setEmailInteracted(true);
						}}
            onInput={(e) => {
              validateDetails();
              setEmailInteracted(true);
            }}
            helperText={emailInteracted ? emailError : ""}
            id="outlined-basic"
            type="email"
            name="email"
            label="Email"
            placeholder=""
            variant="outlined"
            className={classes.textInput}
            onChange={updateInput}
            value={formData.email}
          />

          {/* Physical Address */}
          <TextField
            error={addressInteracted && !formData.address  ? true : false}
						onBlur={(e) => {
              validateDetails();
              setAddressInteracted(true);
						}}
            onInput={(e) => {
              validateDetails();
              setAddressInteracted(true);
            }}
            helperText={addressInteracted ? addressError : ""}
            id="outlined-basic"
            type="text"
            name="address"
            label="Physical Address"
            placeholder=""
            variant="outlined"
            className={classes.textInput}
            onChange={updateInput}
            value={formData.address}
          />

          {/* Power Supply Type */}
          {/* <FormControl component="fieldset"> */}
          <div className={classes.radioInput}>
            <FormLabel component="legend" className={classes.radioLabel}>
              Type of power supply
            </FormLabel>
            <RadioGroup
              aria-label="Type of power supply"
              name="gender1"
              value={phaseType}
              onChange={changePhaseType}
            >
              <FormControlLabel
                value="Single Phase"
                control={<Radio />}
                label="Single Phase"
              />
              <FormControlLabel
                value="Three Phase"
                control={<Radio />}
                label="Three Phase"
              />
            </RadioGroup>
          </div>
          {/* </FormControl> */}

          {/* Selector */}
          <Typography id="discrete-slider-restrict" gutterBottom>
            Average Monthly Electricity Spend (ZAR, Incl VAT)
          </Typography>
          <Typography className={classes.usage} gutterBottom>
            R {electricityUsage}
          </Typography>

          <PrettoSlider
            className={classes.slider}
            value={electricityUsage}
            valueLabelDisplay="off"
            aria-label="pretto slider"
            defaultValue={electricityUsage}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            step={250}
            marks
            min={1000}
            max={5000}
            onChange={changeElectricityUsage}
          />

          {/* Button */}
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.root}
          >
            <Button
              color="secondary"
              variant="outlined"
              type="submit"
              className={classes.sendMessageButton}
              classes={{ disabled: classes.disabledButton }}
              disabled={ !validDetails }
            >
              Get a quote
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={visibleSnackBar}
        autoHideDuration={3000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="success">
          Your quote is on its way!
        </Alert>
      </Snackbar>
    </div>
  );

  const loader = (
    <div>
      <CircularProgress className={classes.loader} />
    </div>
  );

  const emailSent = (
    <div>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Typography className={classes.subTitle} variant="body1">
        Your email has been sent. Please check your inbox for your quote. 
      </Typography>

      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {setFormState(1)}}
        className={classes.sendMessageButton}
      >
        Another Quote
      </Button>
      </Grid>
    </div>
  )

  const currentComponent = (formState == 3) ? emailSent : (formState == 2) ? loader : form;

  return currentComponent;
}

export default QuoteForm