import logo from './logo.svg' 
import './App.css'
import { Button } from '@material-ui/core'
import React from 'react'

// Components
import Navbar from './components/navbar/navbar'
import Footer from './components/footer/footer'
import Proto from './components/proto/proto'

export default function App() {
	return (
		<div>
			<Navbar />
			{/* <Proto /> */}
			<Footer />
		</div>
	)
}

// export default App
