import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';

// Material Icons
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

// React Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

// Images
import Logo from "../../assets/logo/solar365_logo_hori.png";

// Styles
const useStyles = makeStyles((theme) => ({	
	root: {
		flexGrow: 1,
		backgroundColor: "#222",
		maxWidth: "100%",
		margin: 0,
		justifyContent: "center",
		alignItems: "center",
		height: "100%"
	},
	icons: {
		margin: "10px",
		color: "#FFF",
		"&:hover": {
			color: "#FE5023",
		},
	},
	
	Router: {
		height: "100%",
	},

	title: {
		minHeight: "100%",
		justifyContent: "center",
		alignItems: "center",
		color: "#FFF",
		fontFamily: "raleway, sans-serif",
		fontWeight: "900",
		fontSize: "1rem",
	},
	subTitle: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
		fontSize: "0.9rem",
		color: "#FFF",
	},

	link: {
		alignItems: "center",
		color: "#FFF",
		"&:hover": {
			textDecoration: 'none',
		},
	},

	boxLink: {
		padding: "0 30px",
		alignItems: "center",
		color: "#FFF",
		"&:hover": {
			textDecoration: 'none',
			backgroundColor: "#FE5023"
		},
	},

	logo: {
		maxHeight: "70px",
		filter: "invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(80%)",

		"&:hover": {
			filter: "none"	
		}
	},

	solarContactItem: {
		margin: "15px 0"
	}
}));

export default function Footer() {
	const classes = useStyles();

	return (
		<Grid
			className={classes.root}
			container
			direction="row"
		>

			{/* Logo */}
			<Grid
				className={classes.solarContactItem} 
				container
				direction="column"
				justify="center"
				alignItems="center"
				item
				xs={12}
				sm={12}
				md={4} 
				lg={4}
			>
				<Router>
					<Typography
						variant="h6"
						className={classes.title}
						component={RouterLink}
						to="/"
					>
						<img src={Logo} alt="website logo" className={classes.logo} />
					</Typography>
				</Router>
			</Grid>

			{/* Contact Us */}
			<Grid
				className={classes.solarContactItem} 
				container
				direction="column"
				justify="center"
				alignItems="center"
				item
				xs={12}
				sm={12}
				md={4} 
				lg={4}
			>
				<Grid item justify="center">
					<Typography className={classes.title} variant="h6">
						Contact Us
					</Typography>
					
				<Link className={classes.link} href="mailto:info@solar365.co.za" rel="noopener">
					<Box display="flex" flexDirection="row" className={classes.boxLink} justify="center" alignItems="center">
					<EmailIcon className={classes.icons} />
					<Typography className={classes.subTitle} variant="body1">
						Email
					</Typography>
					</Box>
				</Link>
					

				<Link className={classes.link} href="https://wa.me/27826542654" rel="noopener">
					<Box display="flex" flexDirection="row" className={classes.boxLink} justify="center" alignItems="center">
					<WhatsAppIcon className={classes.icons} />
					<Typography className={classes.subTitle} variant="body1">
						WhatsApp
					</Typography>
					</Box>
				</Link>
					
				</Grid>
			</Grid>

			{/* Social Media */}
			<Grid
				className={classes.solarContactItem} 
				container
				direction="column"
				justify="center"
				alignItems="center"
				item
				xs={12}
				sm={12}
				md={4} 
				lg={4}
			>
				<Grid item justify="center">
					<Typography className={classes.title} variant="h6">
						Social Media
					</Typography>
					
					<Link className={classes.link} href="https://www.facebook.com/solar365.co.za/" target="_blank" rel="noopener">
						<Box display="flex" flexDirection="row" className={classes.boxLink} justify="center" alignItems="center">
						<FacebookIcon className={classes.icons} />
						<Typography className={classes.subTitle} variant="body1">
							Facebook
						</Typography>
						</Box>
					</Link>

					<Link className={classes.link} href="https://www.instagram.com/solar365.co.za/" target="_blank" rel="noopener">
						<Box display="flex" flexDirection="row" className={classes.boxLink} justify="center" alignItems="center">
						<InstagramIcon className={classes.icons} />
						<Typography className={classes.subTitle} variant="body1">
							Instagram
						</Typography>
						</Box>
					</Link>

					<Link className={classes.link} href="https://www.linkedin.com/company/solar365za/" target="_blank" rel="noopener">
						<Box display="flex" flexDirection="row" className={classes.boxLink} justify="center" alignItems="center">
						<LinkedInIcon className={classes.icons} />
						<Typography className={classes.subTitle} variant="body1">
							LinkedIn
						</Typography>
						</Box>
					</Link>
					
				</Grid>
			</Grid>


			
		</Grid>
	);
}
