import React from "react";

// Material-ui
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Images
import heroImgSrc from "../../../assets/site/residential.png";
import heroImgSrc2 from "../../../assets/site/solar-home.jpg";

// Styling
const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		height: "800px",
		// background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${heroImgSrc2})`,
		background: `linear-gradient( rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5) ), url(${heroImgSrc2})`,
		backgroundRepeat: "no-repeat",
		// backgroundAttachment: "fixed",
		backgroundSize: "cover",
		// backgroundColor: "rgba(0,0,0,0.7)",

		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		zIndex: -1,
	},
	title: {
		color: "#FFF",
		fontFamily: "raleway, sans-serif",
		fontWeight: "900",
		fontSize: "5rem",
	},
	subTitle: {
		color: "#FFF",
		fontFamily: "raleway, sans-serif",
		fontWeight: "500",
		fontSize: "1.5rem",
	},

	"@media (max-width: 765px)": {
		title: {
			fontSize: "4rem",
			margin: "20px"
		},
		subTitle: {
			margin: "20px"
		},
	},
}));

export default function HeroImgSegment() {
	const classes = useStyles();

	return (
		<Grid
			container
			className={classes.root}
			direction="column"
			justify="center"
			alignItems="center"
		>
			<Typography className={classes.title} variant="h3">
				READY TO TAKE POWER BACK?
			</Typography>
			<Typography className={classes.subTitle} variant="body1"  align="center">
				Learn how Solar365 can provide you with affordable solutions that will{" "}<br />
				save energy costs, provide protection from grid unreliability and <br />
				reduce environmental impact.
			</Typography>
		</Grid>
	);
}
