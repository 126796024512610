import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { tileData } from './images.js';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: "90vw",
		height: "90vh",
		maxHeight: "100vh"
	},

	title: {
		fontColor: "#000",
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		margin: "100px 0px 20px 0px",
	},

	arrowMargin: {
		textAlign: "center",
		marginTop: 20,
		marginBottom: 60,
	},

	arrowBox: {
		fontFamily: "raleway, sans-serif",
		fontWeight: "700",
		position: "relative",
		boxSizing: "border-box",

		'&:before': {
			content: '""',
			border: "solid 4px #fcca03",
			borderWidth: "4px 0 0 4px",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			top: -14,
			left: -60,
		},

		'&:after': {
			content: '""',
			border: "solid 2px #fcca03",
			borderWidth: "0 4px 4px 0",
			height: "32px",
			width: "30px",
			display: "block",
			position: "absolute",
			"box-sizing": "border-box",
			right: -60,
			bottom: -32,

		}
	},
}));

export default function ImageGridList() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.title, classes.arrowMargin} variant="h4">
					<span className={classes.arrowBox} >Past Projects</span>
				</Typography>
			<GridList cellHeight={200} className={classes.gridList} cols={3}>
				{tileData.map((tile) => (
					<GridListTile key={tile.img} cols={tile.cols || 1}>
						<img src={tile.img} alt={tile.title} />
					</GridListTile>
				))}
			</GridList>
		</div>
	);
}
